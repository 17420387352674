<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Client" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <!-- <span v-show="false">{{ (record.is_client = true) }}</span> -->
              <span v-show="false">{{ (record.is_provider = true) }}</span>
              <v-row :dense="dense" class="text-no-wrap" align="center">
                <v-col class="order-0" cols="6" sm="3" lg="2">
                  <v-switch v-model="record.is_company" :hide-details="hided" :dense="dense" :label="$t('Company')" />
                </v-col>
                <v-col class="order-2 order-sm-1" cols="12" sm="4">
                  <v-text-field
                    v-show="record.is_company"
                    v-model="record.invoice.cif_cnp"
                    :hide-details="hided"
                    :dense="dense"
                    :placeholder="$t('CUI')"
                    class="pt-0"
                    @keydown.enter="loadCUIData(record.invoice.cif_cnp)"
                  />
                </v-col>
                <v-col class="order-1 order-sm-2 d-flex justify-end" cols="6" sm="5" lg="6">
                  <v-switch v-model="record.is_client" :hide-details="hided" :dense="dense" :label="$t('Client')" />
                </v-col>
                <v-col class="order-3" cols="12" sm="9">
                  <v-text-field v-model="record.name" :hide-details="hided" :dense="dense" :placeholder="$t('Name')" />
                </v-col>
              </v-row>
              <v-row :dense="dense" class="text-no-wrap" align="center">
                <v-col cols="12" sm="6">
                  <v-subheader>{{ $t("Address") }}</v-subheader>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }">
                      {{ $t("Address") }}
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="record.address.address"
                        :hide-details="hided"
                        :dense="dense"
                        rows="3"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Locality") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="record.address.locality"
                        :hide-details="hided"
                        :dense="dense"
                        class="pt-0"
                      />
                      <!-- <v-autocomplete
                        v-model="record.address.locality"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="diacritice"
                        item-value="value"
                        :items="dictionary.locality"
                        class="pt-0"
                      /> -->
                    </v-col>
                  </v-row>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("County") }}
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="record.address.county"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :items="dictionary.county"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Postal code") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="record.address.postal_code"
                        :hide-details="hided"
                        :dense="dense"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Country") }}
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="record.address.country"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :items="dictionary.country"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-subheader>Contact</v-subheader>
                  <template v-if="!record.is_company">
                    <v-row :dense="dense" class="text-no-wrap" align="center">
                      <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }">
                        {{ $t("Title") }}
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="record.contact.title"
                          :hide-details="hided"
                          :dense="dense"
                          item-text="name"
                          item-value="value"
                          :items="dictionary.client_title"
                          class="pt-0"
                        />
                      </v-col>
                    </v-row>
                    <v-row :dense="dense" class="text-no-wrap" align="center">
                      <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }">
                        {{ $t("Job position") }}
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="record.contact.job_position"
                          :hide-details="hided"
                          :dense="dense"
                          class="pt-0"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <v-row
                    v-for="(itm, ind) in record.contact.emails"
                    :key="ind"
                    :dense="dense"
                    class="text-no-wrap"
                    align="center"
                  >
                    <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }">
                      {{ $t("Email") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        prepend-icon="mdi-plus"
                        append-icon="mdi-minus"
                        :dense="dense"
                        :hide-details="hided"
                        :value="itm"
                        class="pt-0"
                        @click:append="
                          record.contact.emails.length > 1
                            ? record.contact.emails.splice(ind, 1)
                            : record.contact.emails.splice(ind, 1, '')
                        "
                        @click:prepend="record.contact.emails.splice(ind + 1, 0, '')"
                        @input="record.contact.emails[ind] = $event"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(itm, ind) in record.contact.phones"
                    :key="itm.iiid"
                    :dense="dense"
                    class="text-no-wrap"
                    align="center"
                  >
                    <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }">
                      {{ $t("Phone") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="itm.phone"
                        prepend-icon="mdi-plus"
                        append-icon="mdi-minus"
                        :dense="dense"
                        :hide-details="hided"
                        :placeholder="$t('Number')"
                        @click:prepend="addSubItem('contact.phones', ind + 1)"
                        @click:append="delSubItem('contact.phones', ind)"
                      />
                    </v-col>
                  </v-row>

                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Website") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="record.contact.website"
                        :hide-details="hided"
                        :dense="dense"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Tags") }}
                    </v-col>
                    <v-col>
                      <v-text-field :hide-details="hided" :dense="dense" class="pt-0" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <base-material-tabs color="primary">
                    <v-tab>{{ $t("Contacts & Addresses") }}</v-tab>
                    <v-tab>{{ $t("Sales & Purchase") }}</v-tab>
                    <v-tab>{{ $t("Invoice") }}</v-tab>
                    <v-tab>{{ $t("Notes") }}</v-tab>
                    <v-tab-item>
                      <v-card flat class="my-0">
                        <v-card-text>
                          <v-btn
                            color="primary"
                            text
                            @click="searchRelation(record.relations, null, -1, 'contabil_client', null)"
                          >
                            {{ $t("Add") }}
                          </v-btn>
                          <v-row :dense="dense" class="text-no-wrap" align="center">
                            <template v-for="(itm, ind) in record.relations">
                              <v-col :key="itm.iiid" cols="12" sm="6" md="4">
                                <span v-show="false">{{ itm ? "" : record.relations.splice(ind, 1) }}</span>
                                <span v-show="false">{{
                                  record.relations_type[ind] ? "" : (record.relations_type[ind] = "contact")
                                }}</span>
                                <v-icon
                                  class="float-right mt-2 mr-2"
                                  @click="
                                    record.relations.splice(ind, 1)
                                    record.relations_type.splice(ind, 1)
                                  "
                                >
                                  mdi-close
                                </v-icon>
                                <v-menu bottom right offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="float-right mt-2 mr-1" color="primary" v-on="on">
                                      mdi-dots-vertical
                                    </v-icon>
                                  </template>
                                  <v-list dense>
                                    <v-list-item
                                      v-for="tab in dictionary.contact_address"
                                      :key="tab.value"
                                      @click="$set(record.relations_type, ind, tab.value)"
                                    >
                                      {{ tab.name }}
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                                <v-sheet elevation="5" height="120">
                                  <v-row dense class="text-no-wrap" align="center">
                                    <v-col cols="5">
                                      <v-menu absolute bottom right offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-avatar size="120" v-on="on">
                                            <v-icon v-if="record.relations_type[ind] == 'contact'" size="90">
                                              mdi-account
                                            </v-icon>
                                            <v-icon v-else-if="record.relations_type[ind] == 'invoice'" size="90">
                                              mdi-cash
                                            </v-icon>
                                            <v-icon v-else-if="record.relations_type[ind] == 'delivery'" size="90">
                                              mdi-truck
                                            </v-icon>
                                            <v-icon v-else-if="record.relations_type[ind] == 'other'" size="90">
                                              mdi-map-marker
                                            </v-icon>
                                            <v-icon v-else-if="record.relations_type[ind] == 'private'" size="90">
                                              mdi-home-lock
                                            </v-icon>
                                            <v-icon v-else size="90">
                                              mdi-help-circle
                                            </v-icon>
                                          </v-avatar>
                                        </template>
                                        <v-list dense>
                                          <v-list-item
                                            v-for="tab in dictionary.contact_address"
                                            :key="tab.value"
                                            @click="$set(record.relations_type, ind, tab.value)"
                                          >
                                            {{ tab.name }}
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="7">
                                      <template v-if="record.relations_type[ind] == 'contact'">
                                        <div>
                                          <span class="body-2">{{ itm.name }}</span>
                                        </div>
                                        <template v-if="itm.contact">
                                          <div>
                                            <span class="body-2">{{ itm.contact.job_position }}</span>
                                          </div>
                                          <div>
                                            <span class="body-2">{{ itm.contact.email }}</span>
                                          </div>
                                          <template v-if="itm.contact.phones">
                                            <div>
                                              <span class="body-2">{{ itm.contact.phones.phone }}</span>
                                            </div>
                                          </template>
                                        </template>
                                      </template>
                                      <template v-else-if="record.relations_type[ind]">
                                        <div>
                                          <span class="body-2">{{ itm.name }}</span>
                                        </div>
                                        <template v-if="itm.address">
                                          <div>
                                            <span class="text-wrap body-2">{{ itm.address.address }}</span>
                                          </div>
                                          <!-- <div>
                                            <span class="body-2">{{ itm.address.postal_code }}</span>
                                          </div> -->
                                          <div>
                                            <span class="body-2">{{ itm.address.locality }}</span>
                                          </div>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <div>
                                          <span class="body-2">{{ itm.name }}</span>
                                        </div>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-sheet>
                              </v-col>
                            </template>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat class="my-0">
                        <v-card-text>
                          <v-row :dense="dense" class="text-no-wrap" align="start">
                            <v-col cols="12" sm="6">
                              <v-row :dense="dense" class="text-no-wrap" align="center">
                                <span class="d-none">{{ render }}</span>
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("Sales person") }}
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-model="record.invoice.sales_person.fullname"
                                    append-icon="mdi-magnify"
                                    :hide-details="hided"
                                    :dense="dense"
                                    class="pt-0"
                                    @click:append="
                                      searchRelation(record.invoice, 'sales_person', null, 'app_user', null)
                                    "
                                  />
                                </v-col>
                              </v-row>
                              <v-row
                                v-for="(itm, ind) in record.contact.industry"
                                :key="ind"
                                :dense="dense"
                                class="text-no-wrap"
                                align="center"
                              >
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("Industry") }}
                                </v-col>
                                <v-col>
                                  <v-autocomplete
                                    prepend-icon="mdi-plus"
                                    append-icon="mdi-minus"
                                    :hide-details="hided"
                                    :dense="dense"
                                    item-text="name"
                                    item-value="value"
                                    :items="dictionary.industry"
                                    :value="itm"
                                    class="pt-0"
                                    @click:append="
                                      record.contact.industry.length > 1
                                        ? record.contact.industry.splice(ind, 1)
                                        : record.contact.industry.splice(ind, 1, '')
                                    "
                                    @click:prepend="record.contact.industry.splice(ind + 1, 0, '')"
                                    @input="record.contact.industry[ind] = $event"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-row :dense="dense" class="text-no-wrap" align="center">
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("Payment terms") }}
                                </v-col>
                                <v-col>
                                  <v-combobox
                                    :value="record.invoice.terms"
                                    :hide-details="hided"
                                    :dense="dense"
                                    item-text="name"
                                    item-value="value"
                                    :return-object="true"
                                    :items="dictionary.payment_terms"
                                    class="pt-0"
                                    @input="
                                      $event.value
                                        ? (record.invoice.terms = $event.value)
                                        : (record.invoice.terms = $event)
                                    "
                                  />
                                </v-col>
                              </v-row>
                              <v-row :dense="dense" class="text-no-wrap" align="center">
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("Fiscal position") }}
                                </v-col>
                                <v-col>
                                  <v-select
                                    v-model="record.invoice.fiscal_position"
                                    :hide-details="hided"
                                    :dense="dense"
                                    item-text="name"
                                    item-value="value"
                                    :items="dictionary.fiscal_position"
                                    class="pt-0"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat class="my-0">
                        <v-card-text>
                          <v-row :dense="dense" class="text-no-wrap" align="center">
                            <v-col cols="12" sm="6">
                              <v-row :dense="dense" class="text-no-wrap" align="center">
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("CIF/CNP") }}
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-model="record.invoice.cif_cnp"
                                    :hide-details="hided"
                                    :dense="dense"
                                    class="pt-0"
                                    @keydown.enter="checkTVA(record.invoice.cif_cnp)"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-row :dense="dense" class="text-no-wrap" align="center">
                                <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                  {{ $t("Reg com") }}
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-model="record.invoice.reg_com"
                                    :hide-details="hided"
                                    :dense="dense"
                                    class="pt-0"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12">
                              <v-row
                                v-for="(itm, ind) in record.invoice.banks"
                                :key="itm.iiid"
                                :dense="dense"
                                class="text-no-wrap"
                                align="center"
                              >
                                <v-col cols="12" sm="6">
                                  <v-row :dense="dense" class="text-no-wrap" align="center">
                                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                      {{ $t("Bank") }}
                                    </v-col>
                                    <v-col>
                                      <v-text-field
                                        v-model="itm.bank"
                                        prepend-icon="mdi-plus"
                                        :hide-details="hided"
                                        :dense="dense"
                                        class="pt-0"
                                        @click:prepend="addSubItem('invoice.banks', ind + 1)"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-row :dense="dense" class="text-no-wrap" align="center">
                                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                                      {{ $t("IBAN") }}
                                    </v-col>
                                    <v-col>
                                      <v-text-field
                                        v-model="itm.iban"
                                        append-icon="mdi-minus"
                                        :hide-details="hided"
                                        :dense="dense"
                                        class="pt-0"
                                        @click:append="delSubItem('invoice.banks', ind)"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-row :dense="dense" class="text-no-wrap" align="center" no-gutters>
                                <v-col cols="3" class="text-right grey--text" :class="{ 'pt-3': hided }" />
                                <v-col>
                                  <v-checkbox
                                    v-model="record.invoice.tva"
                                    :hide-details="hided"
                                    :dense="dense"
                                    label="TVA"
                                    class="mt-0"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat class="my-0">
                        <v-card-text>
                          <v-row :dense="dense" class="text-no-wrap" align="center">
                            <v-col>
                              <v-textarea :hide-details="hided" :dense="dense" :label="$t('Note')" class="pt-0" />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </base-material-tabs>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink
  },
  data() {
    return {
      dense: true,
      hided: false,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkIndex: null,
      render: 1,
      record: {
        is_company: "",
        type: "",
        name: "Scream",
        address: { type: "", address: "", locality: "", county: "", postal_code: "", country: "" },
        contact: {
          title: "",
          job_position: "",
          emails: [""],
          phones: [{ type: "", phone: "" }],
          website: "",
          industry: ["it_communication"]
        },
        tags: [""],
        relations: [""],
        relations_type: [""],
        invoice: {
          sales_person: "",
          terms: 15,
          fiscal_position: "",
          cif_cnp: "",
          reg_com: "",
          tva: "",
          banks: [{ bank: "", iban: "" }]
        },
        note: ""
      },
      recordView: {
        is_company: "",
        type: "",
        name: "",
        address: { type: "", address: "", locality: "", county: "", postal_code: "", country: "" },
        contact: {
          title: "",
          job_position: "",
          emails: [""],
          phones: [{ type: "", phone: "" }],
          website: "",
          industry: ["it_communication"]
        },
        tags: [""],
        relations: [],
        relations_type: [],
        invoice: {
          sales_person: "",
          terms: 15,
          fiscal_position: "",
          cif_cnp: "",
          reg_com: "",
          tva: "",
          banks: [{ bank: "", iban: "" }]
        },
        note: ""
      },
      schema: {
        dictionary: [
          "all.client_title",
          "all.contact_address",
          "all.fiscal_position",
          "all.payment_terms",
          "all.industry",
          "all.country",
          "all.county"
          // "all.locality"
        ]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },

    getDictionaryValues(dic) {
      // this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLinkValue(e) {
      if (this.linkIndex) {
        if (this.linkField) {
          this.linkArray[this.linkIndex][this.linkField] = e
        } else {
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            this.linkArray[this.linkIndex] = e
          }
        }
      } else {
        this.linkArray[this.linkField] = e
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
